#root {
  height: 100vh;
}
.aside-container {
  min-height: 100%;
}

#header {
  position: relative;
  z-index: 10;
  max-width: 100%;
  background: #fff;
  box-shadow: 0 2px 8px #f0f1f2;
}
.aside-container{
  padding-bottom: 20px;
}
.aside-container.ant-menu-inline > .ant-menu-item,
.aside-container.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
.aside-container.ant-menu-inline > .ant-menu-item-group > .ant-menu-item-group-title,
.aside-container.ant-menu-inline > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.aside-container.ant-menu-inline.ant-menu-inline > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item {
  padding-left: 40px !important;
}
.aside-container.ant-menu-inline > .ant-menu-item-group > .ant-menu-item-group-title {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 13px;
}
.aside-container.ant-menu-inline > .ant-menu-item-group > .ant-menu-item-group-title:after {
  position: relative;
  top: 12px;
  display: block;
  width: calc(100% - 20px);
  height: 1px;
  background: rgba(0, 0, 0, 0.06);
  content: '';
}

.main-wrapper {
  position: relative;
  padding: 40px 0 0;
  background-color: #fff;
  height: calc(100vh - 64px);
  overflow-y: auto;
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}

.ant-input-number-input {
  text-align: right;
}
